import React from "react";
import { Link } from "gatsby";
import PortableText from "react-portable-text";
import Button from "../common/buttons/Button";

const RegenrativeAdventure = ({ data, show = "show" }) => {
  return (
    <div
      className={`relative  w-full box-border ${show}`}
      style={{
        backgroundImage: `linear-gradient(to bottom,rgba(214, 214, 214, 0.90),rgba(192, 192, 192, 0.90)),url(${data?.bgImage?.asset?.url})`,
        backgroundSize: "initial",
      }}
    >
      <div className="max-w-[1100px] px-2 sm:px-0 w-full mx-auto py-6">
        <div className="flex flex-col flex-wrap justify-center p-[10px] sm:p-5">
          {/* Icon */}
          <div className="block relative w-full">
            <div className="block w-full text-center">
              {data?.svg && (
                <Link to={data.mainlink} className="cursor-pointer">
                  <div
                    dangerouslySetInnerHTML={{ __html: data.svg }}
                    className="flex justify-center birding-svg"
                  />
                </Link>
              )}
            </div>
          </div>
          {/* Heading */}
          <div className="block relative w-full">
            <h1
              className={`text-[40px] sm:text-[50px] leading-[50px] sm:leading-[63px] font-bold text-center text-[#323A44] tracking-[2px]`}
            >
              {data?.heading}
            </h1>
          </div>
          {/* Subheading */}
          <div className="block relative w-full">
            <div className="pb-5 pt-2 w-full text-center">
              <h1
                className={`text-[20px] sm:text-[30px] leading-[35px] sm:leading-[41px] uppercase font-semibold tracking-[2px] text-[#323A44]`}
              >
                {data?.subheading}
              </h1>
            </div>
            <p className="mb-[2.5rem]">
              {(data?._rawContent || data?.content) && (
                <PortableText
                  content={data?._rawContent || data?.content}
                  className="font-semibold text-assent text-sm sm:text-base leading-[26px] block-content max-w-none"
                />
              )}
            </p>
            {data?.ctaButton1?.title && (
              <div className="flex justify-center">
                <Button
                  btnText={data?.ctaButton1?.title}
                  btnLink={data?.ctaButton1?.link}
                  btnType={data?.ctaButton1?.variant}
                  linkType={data?.ctaButton1?.type}
                  formId={data?.ctaButton1?.formId}
                  downloadLink={data?.ctaButton1?.downloadLink}
                  small
                  height="h-[50px]"
                />
              </div>
            )}
          </div>
          {/* Content */}
          <div
            className={`grid grid-cols-1 gap-y-6 gap-x-5 sm:gap-y-[80px] sm:gap-x-12 sm:grid-cols-2 mb-16 w-full mx-auto mt-6`}
          >
            {data?.bookItem?.map((item, index) => {
              return (
                <div key={index}>
                  <Link to={item.link} className="cursor-pointer">
                    <div className="flex justify-center">
                      <div dangerouslySetInnerHTML={{ __html: item.svg }} />
                    </div>
                  </Link>

                  <p className="text-[20px] leading-[27px] text-assent mt-3  font-semibold text-center tracking-[2px] mb-3">
                    {item?.title}
                  </p>
                  {(item?._rawDetail || item?.detail) && (
                    <PortableText
                      content={item?._rawDetail || item?.detail}
                      className="portable-editor text-sm sm:text-base font-semibold text-assent block-content max-w-none"
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className="w-[95%] mx-auto pt-[50px]">
            <div className="flex flex-row flex-wrap max-w-full align-top items-stretch justify-around">
              {/* Button 1 */}

              {data?.ctaButton2?.title && (
                <div
                  className="w-full sm:w-custom-sm lg:w-custom-lg mb-[10px]
              flex flex-col flex-wrap justify-start relative"
                >
                  <div className="relative block pt-5 pb-0 md:pb-10 m-[1px]">
                    <Button
                      btnText={data?.ctaButton2?.title}
                      btnLink={data?.ctaButton2?.link}
                      btnType={data?.ctaButton2?.variant}
                      linkType={data?.ctaButton2?.type}
                      formId={data?.ctaButton2?.formId}
                      downloadLink={data?.ctaButton2?.downloadLink}
                    />
                  </div>
                </div>
              )}

              {/* Button 2 */}
              {data?.ctaButton3?.title && (
                <div
                  className="w-full sm:w-custom-sm lg:w-custom-lg mb-[10px]
              flex flex-col flex-wrap justify-start relative"
                >
                  <div className="relative block pt-5 pb-0 md:pb-10 m-[1px]">
                    <Button
                      btnText={data?.ctaButton3?.title}
                      btnLink={data?.ctaButton3?.link}
                      btnType={data?.ctaButton3?.variant}
                      linkType={data?.ctaButton3?.type}
                      formId={data?.ctaButton3?.formId}
                      downloadLink={data?.ctaButton3?.downloadLink}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegenrativeAdventure;
